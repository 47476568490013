import { Avatar, AvatarButtonGroup, Loader } from 'src/design-system'
import { formatDate } from 'app/packs/src/utils/date-helpers'
import { avatarGroupProps, avatarProps } from 'app/packs/src/utils/user-helpers'
import { Collapsible } from 'components/atoms/collapsible'
import { Select } from 'components/atoms/select'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { SkillLevelIndicator } from 'components/skill-level-indicator'
import React, { useEffect, useState } from 'react'
import { store } from 'store/index'
import { Checkin } from 'store/modules/checkins'
import { SkillLevel } from 'store/modules/skill-levels'
import { SkillVariant } from 'store/modules/skill-variants'
import { User } from 'store/modules/users'

export function PeopleSection({ variant }: { variant: SkillVariant }) {
  const [teamId, setTeamId] = useState('all')
  const teams = store.teams.sortedForCurrentUser

  // Check if there are any users across all levels
  const hasUsers = variant.skillLevels.some(
    (level) => getUsersForLevel(level, teamId, variant).length > 0
  )

  return (
    <div className="flex flex-col gap-6 justify-stretch">
      <div className="flex items-center justify-between">
        <h3 className="font-bold text-xl">People with skill</h3>
        <Select
          align="end"
          isSearchable
          value={teamId}
          onChange={setTeamId}
          options={[
            { value: 'all', label: 'All Teams' },
            ...teams.map((t) => ({ value: t.id, label: t.name })),
          ]}
        />
      </div>

      {!hasUsers ? (
        <div className="text-gray-500 text-sm">
          There are no people in positions that require this skill.
        </div>
      ) : (
        <div className="flex-col gap-2">
          {variant.skillLevels
            .sort((a, b) => a.level - b.level)
            .map((level) => (
              <SkillLevelRow
                level={level}
                teamId={teamId}
                variant={variant}
                key={level.id}
              />
            ))}
        </div>
      )}
    </div>
  )
}

function SkillLevelRow({
  teamId,
  level,
  variant,
}: {
  teamId: string
  level: SkillLevel
  variant: SkillVariant
}) {
  const users = getUsersForLevel(level, teamId, variant)

  if (!users.length)
    return (
      <div className="flex gap-5 py-4 items-center w-full">
        <SkillLevelIndicator level={level.level} maxLevel={variant.maxLevel} />
        <div className="flex items-center">
          <div className="w-8 h-8 first:ml-0 -ml-2 bg-white rounded-full border-solid border-px border-gray-100" />
          <div className="w-8 h-8 first:ml-0 -ml-2 bg-white rounded-full border-solid border-px border-gray-100/75" />
          <div className="w-8 h-8 first:ml-0 -ml-2 bg-white rounded-full border-solid border-px border-gray-50" />
          <div className="w-8 h-8 first:ml-0 -ml-2 bg-white rounded-full border-solid border-px border-gray-50/50" />
        </div>
      </div>
    )

  return (
    <Collapsible
      scrollable
      triggerClassName="flex gap-5 py-4 w-full items-center px-3 hover:bg-gray-50/50"
      className="-mx-3"
      key={level.id}
      label={
        <>
          <SkillLevelIndicator
            level={level.level}
            maxLevel={variant.maxLevel}
          />
          <AvatarButtonGroup
            className="mr-auto"
            avatars={avatarGroupProps(users, {
              href: false,
              stateTooltip: true,
            })}
            size="sm"
            limit={3}
            limitStyle="tag"
          />
        </>
      }
      defaultOpen={false}
    >
      <SkillLevelRowContent users={users} />
    </Collapsible>
  )
}

function SkillLevelRowContent({ users }: { users: User[] }) {
  const [loading, setLoading] = useState(true)
  const sortedCheckins = React.useMemo(() => {
    const checkins: Array<[User, Checkin | undefined]> = users.map((user) => [
      user,
      store.checkins.lastFinalisedUserCheckin(user.id),
    ])

    return checkins.sort(([_a, a], [_b, b]) => {
      if (!a?.finalisedAt && !b?.finalisedAt) return 0
      if (!a?.finalisedAt) return 1
      if (!b?.finalisedAt) return -1
      return a?.finalisedAt > b?.finalisedAt ? -1 : 1
    })
  }, [users])

  // each row is 80px high + 1px for the border
  // we max out 3.5 so it's clear you can scroll to see more
  const height = Math.min(users.length, 3.5) * 81 + 1

  useEffect(() => {
    async function fetchLatestCheckin() {
      await store.checkins.fetchAll({
        filter: { last_finalised: true, author_id: users.map((u) => u.id) },
      })
      setLoading(false)
    }

    fetchLatestCheckin()
  }, [users])

  if (loading)
    return (
      <div className="px-3 py-4" style={{ height }}>
        <Loader />
      </div>
    )
  return (
    <>
      {sortedCheckins.map(([user, checkin]) => (
        <div
          className="px-3 py-4 flex gap-4 items-center border-solid border-gray-50 border-0 border-b"
          key={user.id}
        >
          <Avatar size="md" {...avatarProps(user)} />
          <div className="flex flex-col mr-auto">
            <p className="font-bold mb-0">{user.fullName}</p>
            <p className="text-sm text-gray-600 mb-0">{user.position?.name}</p>
          </div>
          {checkin?.finalisedAt && (
            <p className="mb-0">
              {formatDate(checkin.finalisedAt, { relative: true })}
            </p>
          )}
          <CheckinStatusLabel
            status={checkin?.summaryStatus || 'not_checked_in'}
          />
        </div>
      ))}
    </>
  )
}

function getUsersForLevel(
  level: SkillLevel,
  teamId: string,
  variant: SkillVariant
) {
  const userSet: User[] =
    teamId === 'all' ? store.users.all : store.teams.byId(teamId)?.users ?? []
  return userSet.filter(
    (u) =>
      u.isActive &&
      !!u.position?.requirements.some(
        (r) => r.level === level.level && r.skillVariant.id === variant.id
      )
  )
}
