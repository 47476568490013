import { Button, DrawerButton, SKILLS_DRAWER_ID } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SkillsDrawer } from 'components/skills-drawer'
import { store } from 'store/index'
import { useDrawerSkills } from 'app/packs/src/utils/use-drawer-skills'
import { WithAnalytics } from 'components/with-analytics'
import * as SecondaryNav from 'components/secondary-nav'
import { DownloadSimple } from '@phosphor-icons/react'
import { Dropdown } from 'src/design-system'

type NavProps = {
  defaultOpen?: boolean
  onRefresh?: () => void
}

export const Nav = observer((props: NavProps) => {
  const { onRefresh, defaultOpen } = props

  const source = 'library'

  const { onClickCollection } = useDrawerSkills(source)

  const canAddSkills = !!store.currentUser && store.currentUser.isAdminOrEditor
  const canImportSkills = !!store.currentUser && store.currentUser.isAdmin
  const downloadLink = `/orgs/${store.currentUser?.org?.slug}/skills/download.csv`

  return (
    <>
      <SecondaryNav.Root>
        <div className="flex items-center justify-between w-full">
          <SecondaryNav.Header>Skills</SecondaryNav.Header>
          {(canAddSkills || canImportSkills) && (
            <div className="flex gap-2 items-center">
              {canImportSkills && (
                <Dropdown.Root>
                  <Dropdown.Trigger
                    variant="styled"
                    colourVariant="subtle"
                    variantStyle="outline"
                    size="nav"
                  >
                    Import / Export skills
                  </Dropdown.Trigger>
                  <Dropdown.Content className="mr-8 w-[180px]">
                    <Dropdown.Link
                      className="text-left text-gray-900"
                      data-turbo-action="advance"
                      href={`/skill_imports/new`}
                    >
                      Import / Export skills
                    </Dropdown.Link>
                    <Dropdown.Link
                      className="text-left text-gray-900 flex items-center"
                      data-turbo-action="advance"
                      href={downloadLink}
                    >
                      Export skill usage data
                    </Dropdown.Link>
                  </Dropdown.Content>
                </Dropdown.Root>
              )}
              {canAddSkills && (
                <WithAnalytics
                  event="$track_org_secondary_nav_click"
                  params={{ button: 'Add skills' }}
                >
                  <DrawerButton drawerId={SKILLS_DRAWER_ID}>
                    Add skills
                  </DrawerButton>
                </WithAnalytics>
              )}
            </div>
          )}
        </div>
      </SecondaryNav.Root>
      {canAddSkills && (
        <SkillsDrawer
          defaultOpen={defaultOpen}
          onClickCollection={onClickCollection}
          refreshProps={() => onRefresh?.()}
          source={source}
          title="Add skills to your org"
        />
      )}
    </>
  )
})
