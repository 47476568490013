import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { useStore } from 'store/context'
import { ZodError } from 'zod'
import * as React from 'react'
import cn from 'classnames'
import { Editor, EditorContext } from 'components/atoms/editor'
import { FeedbackFormFooter } from './feedback-form-footer'
import { FeedbackFormProps } from './feedback-form.types'
import { FeedbackFormVm } from './feedback-form-vm'
import { UserSelect } from 'components/user-select'
import { Toggle, ToggleItem } from 'components/atoms/toggle'
import { VisibilitySelect } from 'components/visibility-select'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { successToast } from 'app/packs/src/utils/success-toast'
import styles from './feedback-form.module.scss'

const toggleClasses = (active: boolean) =>
  cn('ml-0 flex items-center rounded h-full', {
    'bg-gray-50 text-gray-500': !active,
    'bg-white text-gray-900 shadow-sm': active,
  })

export const FeedbackForm = observer((props: FeedbackFormProps) => {
  const {
    autoFocus,
    initialContent,
    setContent,
    onSuccessfulSubmit,
    initialUserIds,
    feedbackRequestId,
    startOnFromTab,
  } = props

  const { users, feedbackRequests } = useStore()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formError, setFormError] = React.useState<string | null>(null)
  const [submitCount, setSubmitCount] = React.useState(0)
  const editor = React.useRef<EditorContext>(null)

  let initialFromState: boolean =
    feedbackRequestId || initialUserIds ? false : true
  if (startOnFromTab) initialFromState = true

  const [from, setFrom] = React.useState(initialFromState)

  const vm = React.useMemo(() => {
    return new FeedbackFormVm(from, feedbackRequestId)
  }, [from, feedbackRequestId])

  React.useEffect(() => {
    if (feedbackRequestId) {
      feedbackRequests.fetchOne(feedbackRequestId)
    }
  }, [users, feedbackRequests, feedbackRequestId])

  const feedbackRequest = feedbackRequestId
    ? feedbackRequests.byId(feedbackRequestId)
    : undefined

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setIsSubmitting(true)

      try {
        await vm.onSubmit(e)
        setSubmitCount(submitCount + 1)
        editor.current?.reset()
        successToast(vm.successMessage)
        onSuccessfulSubmit?.()
      } catch (errors) {
        if (errors instanceof ZodError) {
          setFormError('Please enter content.')
        } else {
          setFormError('Something went wrong. Please try again.')
        }

        // TODO: better error handling
        console.error({ errors })
      } finally {
        setIsSubmitting(false)
      }
    },
    [onSuccessfulSubmit, submitCount, vm]
  )

  return (
    <>
      <form className="pb-6 px-6" onSubmit={onSubmit} id="feedback-form">
        <div className="flex flex-row flex-1 gap-x-4 mb-2.5 -mt-2">
          <div>
            <Toggle
              value={from ? 'from' : 'to'}
              onChange={(f) => setFrom(f === 'from')}
              className="my-1 flex flex-row border-gray-100 border-solid border-px rounded bg-gray-50"
            >
              {(val) => (
                <>
                  <div className={toggleClasses(val === 'from')}>
                    <ToggleItem
                      className="px-2 mx-0 whitespace-nowrap"
                      value="from"
                    >
                      Request from
                    </ToggleItem>
                  </div>
                  <div className={toggleClasses(val === 'to')}>
                    <ToggleItem
                      className="px-2 mx-0 whitespace-nowrap"
                      value="to"
                    >
                      Give to
                    </ToggleItem>
                  </div>
                </>
              )}
            </Toggle>
          </div>
          {from ? (
            <UserSelect
              defaultValue={initialUserIds}
              key={`user-select-${submitCount}`}
              users={users.withPackage('grow')}
              isMulti
              required
              className="w-full"
              includeCurrentUser={false}
            />
          ) : (
            <UserSelect
              defaultValue={initialUserIds?.find((x) => x !== undefined)}
              key={`user-select-${submitCount}`}
              users={users.withPackage('grow')}
              required
              className="w-full"
              includeCurrentUser
            />
          )}
        </div>
        {!from && feedbackRequest && (
          <div
            className="rounded-sm mb-4 bg-blue-100 items-center relative"
            style={{
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="10"
              fill="none"
              className="absolute -top-2.5 left-[7.25rem]"
            >
              <path
                className="fill-current text-blue-100"
                d="m10 0 10 10H0L10 0Z"
              />
            </svg>
            <HtmlContent className={styles.htmlContent}>
              {feedbackRequest.content}
            </HtmlContent>
          </div>
        )}
        <Editor
          key={vm.placeholder}
          ref={editor}
          name="content"
          autoFocus={autoFocus}
          onChange={setContent}
          placeholder={vm.placeholder}
          initialContent={initialContent}
          className="mb-4 -mx-2"
          rows={4}
        />
        <div className="flex flex-col gap-y-2 sm:flex-row items-start sm:justify-between">
          {!vm.from && (
            <VisibilitySelect
              disabled
              includedStatuses={['reporting_line']}
              isForCurrentUser={false}
              isMultiUser={false}
            />
          )}
          <Button
            disabled={isSubmitting}
            className="ml-auto my-px"
            type="submit"
          >
            Send
          </Button>
        </div>
        {formError && <div className="mt-1 text-red-600">{formError}</div>}
      </form>
      <FeedbackFormFooter vm={vm} />
    </>
  )
})
