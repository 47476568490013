import '@hotwired/turbo-rails'
import '@hotwired/stimulus'
import '@algolia/autocomplete-theme-classic/dist/theme.css'
import 'bootstrap'
import { closeModal } from '../src/utils/close-modal'
import { exceptions } from '../src/utils/exceptions'
import { hydrateReactComponents } from '../src/utils/react/hydrate'
import { unmountReactComponents } from '../src/utils/react/unmount-components'
import '../stylesheets/main.scss'
import 'app/javascript/controllers'
import 'app/assets/stylesheets/combobox.css'
import 'app/assets/stylesheets/position_alias.css'

if (typeof window !== 'undefined') {
  exceptions.setup()

  window.Turbo.session.drive = true

  window.closeModal = closeModal
  hydrateReactComponents()

  window.hydrateReactComponents = hydrateReactComponents
  window.$(document).on('modal:loaded', () => {
    hydrateReactComponents({ root: '#modal-anchor' })
  })
  document.addEventListener('turbo:load', () => {
    hydrateReactComponents()
  })

  document.addEventListener('turbo:frame-render', () => {
    hydrateReactComponents()
  })

  document.addEventListener('turbo:before-render', unmountReactComponents)

  document.addEventListener('turbo:frame-missing', (event) => {
    const {
      detail: { response, visit },
    } = event as any

    event.preventDefault()
    visit(response.url)
  })
}
