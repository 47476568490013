import { IAnyType, SnapshotIn } from 'mobx-state-tree'
import { ZodError } from 'zod'
import { RequestCache } from './cache'
import { JSONAPIErrorsSchema } from './contract'
import {
  ClientOptions,
  JSONAPIRequestParams,
  Method,
  OperationAttribute,
  RequestError,
  RequestOptions,
  RequestResult,
} from './types'
import { deserialize } from './utils/deserialize'
import { getModelType } from './utils/get-model-type'
import { ISerializer, Serializer } from './utils/serialize'
import { RequestParams, url } from './utils/url'

export type ApiClientDependencies<Model extends IAnyType> = {
  url: typeof url
  serializer: ISerializer<Model>
  deserialize: typeof deserialize
}

export type ApiClientOptions = {
  baseUrl: string
  version?: string
}

export class ApiClient<
  Model extends IAnyType,
  Filters extends RequestParams | undefined = undefined,
  Include extends string[] | undefined = undefined,
  CreateAttributes extends Partial<SnapshotIn<Model>> | undefined = undefined,
  UpdateAttributes extends Partial<SnapshotIn<Model>> | undefined = undefined
> {
  static cache = new RequestCache()

  constructor(
    private readonly model: Model,
    private readonly options: ApiClientOptions,
    private readonly deps: ApiClientDependencies<Model> = {
      url,
      serializer: new Serializer<Model, SnapshotIn<Model>>(model),
      deserialize,
    }
  ) {}

  private type = getModelType(this.model).name

  async getMany(
    params?: JSONAPIRequestParams<Include, Filters>,
    options?: ClientOptions
  ) {
    return this.request('GET', { params }, options)
  }

  async getOne(
    id: string,
    params?: JSONAPIRequestParams<Include, Filters>,
    options?: ClientOptions
  ) {
    return this.request('GET', { id, params }, options)
  }

  async create(
    body: CreateAttributes,
    params?: JSONAPIRequestParams<Include, Filters>,
    options?: ClientOptions
  ) {
    return this.request('POST', { body, params }, options)
  }

  async update(
    id: string,
    body: UpdateAttributes,
    params?: JSONAPIRequestParams<Include, Filters>,
    options?: ClientOptions
  ) {
    return this.request('PUT', { id, body, params }, options)
  }

  async destroy(
    id: string,
    params?: JSONAPIRequestParams<Include, Filters>,
    options?: ClientOptions
  ) {
    return this.request('DELETE', { id, params }, options)
  }

  async operations(operations: OperationAttribute[]): Promise<RequestResult> {
    const body = { 'atomic:operations': operations }
    try {
      const response = await fetch('/api/v1/operations', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/vnd.api+json' },
      })
      const json = await response.json()
      return { success: true, ...json }
    } catch (e) {
      console.error(e)
      return { success: false, message: (e as Error).message }
    }
  }

  private async request(
    method: Method,
    { id, body, params = {} }: RequestOptions<Include, Filters> = {},
    { namespace, bypassCache, abortSignal, customUrl }: ClientOptions = {}
  ): Promise<RequestResult> {
    const serializedBody = this.deps.serializer.call(body, id)

    const url =
      customUrl ||
      this.deps.url(this.options.baseUrl, {
        type: this.type,
        id,
        params,
        namespace,
      })

    const options = {
      method,
      body: JSON.stringify(serializedBody),
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      signal: abortSignal,
      credentials: 'include', // Ensures cookies are included in the request
    } satisfies RequestInit

    const {
      response,
      json,
      cached = false,
    } = await ApiClient.cache.performRequest(url, options, bypassCache)
    let queryData: RequestError['extra'] = {
      url,
      id,
      params,
      namespace,
      body,
      method,
      type: this.type,
      status: response.status,
      statusText: response.statusText,
    }
    const requestId = response.headers.get('X-Request-Id')

    try {
      if (response.ok) {
        const body = this.deps.deserialize(json)
        return {
          success: true,
          cached,
          requestId,
          ...body,
        }
      }

      const apiErrors = JSONAPIErrorsSchema.safeParse(json)
      queryData = {
        ...queryData,
        responseJson: json,
      }

      return {
        message: 'API request error',
        success: false,
        errors: apiErrors.success ? apiErrors.data.errors : undefined,
        extra: queryData,
        requestId,
      }
    } catch (e) {
      console.error(e)
      const result = {
        success: false,
        message: 'Unknown API request error',
        extra: queryData,
      } as const

      if (e instanceof ZodError) {
        return {
          ...result,
          message: 'Failed to parse response',
          errors: e.flatten(),
        }
      }

      if (e instanceof Error) {
        throw e
      }

      return result
    }
  }
}
