import React from 'react'

export const DeletedSkillContext = React.createContext({
  lastDeletedSkillId: null as string | null,
  setLastDeletedSkillId: (id: string) => {},
})

export const RemoveSkillContext = React.createContext({
  lastRemovedSkillId: null as string | null,
  setLastRemovedSkillId: (id: string) => {},
})

export const BulkDeletedSkillContext = React.createContext<{
  checkedSkillIds: string[]
  addCheckedSkillId: (id: string) => void
  removeCheckedSkillId: (id: string) => void
}>({
  checkedSkillIds: [],
  addCheckedSkillId: () => {},
  removeCheckedSkillId: () => {},
})
