import * as React from 'react'
import { Phone } from '@phosphor-icons/react'

export const BookADemo = () => {
  return (
    <a
      target={'_blank'}
      href="https://meetings-eu1.hubspot.com/meetings/rory-mccarthy"
      className="flex-1 flex flex-row gap-x-4 items-center border-px border-solid border-gray-100 hover:border-gray-200 rounded-lg p-4 transition-colors"
      rel="noreferrer"
    >
      <div className="rounded bg-pink-100 text-pink-600 w-20 flex-shrink-0 h-full flex items-center justify-center">
        <Phone aria-hidden weight="bold" size="24" />
      </div>
      <div className="flex flex-col gap-y-1">
        <h3 className="font-bold text-gray-900 text-sm">
          Get help from our team
        </h3>
        <p className="text-gray-600 text-xs mb-0">
          Talk through your needs in detail by booking a demo with our team of
          experts.
        </p>
      </div>
    </a>
  )
}
