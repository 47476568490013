import { AvatarButtonGroup, Tag } from 'src/design-system'
import { CellContext } from '@tanstack/react-table'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { OrgSkillsTableRow } from '../data'

export const PeopleCell = observer(
  (data: CellContext<OrgSkillsTableRow, string[]>) => {
    const { first3UserIds, userCount } = data.row.original
    const users = first3UserIds.flatMap((id) => store.users.byId(id) || [])

    return (
      <div className="flex items-center gap-2">
        <span>{userCount}</span>
        {userCount > 0 && (
          <AvatarButtonGroup
            avatars={avatarGroupProps(users, {
              href: false,
              stateTooltip: true,
            })}
            size="xs"
          />
        )}
      </div>
    )
  }
)
