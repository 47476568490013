import { Application } from "@hotwired/stimulus"
import '@hotwired/turbo';
import HwComboboxController from "@josefarias/hotwire_combobox"

const application = Application.start()

application.register("hw-combobox", HwComboboxController)

application.debug = false
window.Stimulus = application

export { application }
