import * as React from 'react'
import { OverviewCard } from './overview-card'
import { Sparkle } from '@phosphor-icons/react'

export type OverviewCardType =
  | 'needAttention'
  | 'total'
  | 'unused'
  | 'unusedInTeam'

export type OverviewCardsProps = {
  needAttentionCount: number
  onClick: (overviewCardType: OverviewCardType) => void
  selectedCard: OverviewCardType
  setSelectedCard: (overviewCardType: OverviewCardType) => void
  totalCount: number
  unusedCount: number
  unusedInTeamCount: number
}

export const OverviewCards = (props: OverviewCardsProps) => {
  const {
    needAttentionCount,
    onClick,
    selectedCard,
    setSelectedCard,
    totalCount,
    unusedCount,
    unusedInTeamCount,
  } = props

  const selectCard = (overviewCardType: OverviewCardType) => {
    onClick(overviewCardType)
    setSelectedCard(overviewCardType)
  }

  const selected = (overviewCardType: OverviewCardType) => {
    return selectedCard === overviewCardType
  }

  return (
    <div className="flex gap-3 bg-gray-50 text-green-600 items-center p-3 overflow-x-auto">
      <Sparkle size={20} weight="fill" className="flex-shrink-0" />
      Quick filters
      <OverviewCard
        description="All skills"
        filterText="Show all skills"
        onClick={() => selectCard('total')}
        selected={selected('total')}
        value={totalCount}
      />
      <OverviewCard
        description="Unused"
        filterText="Show skills"
        onClick={() =>
          selected('unused') ? selectCard('total') : selectCard('unused')
        }
        selected={selected('unused')}
        tooltipText="Skills that aren’t used in any teams or positions within a team"
        value={unusedCount}
      />
      <OverviewCard
        description="Unused in team"
        filterText="Show skills"
        onClick={() =>
          selected('unusedInTeam')
            ? selectCard('total')
            : selectCard('unusedInTeam')
        }
        selected={selected('unusedInTeam')}
        tooltipText="Skills used by teams but not by any positions"
        value={unusedInTeamCount}
      />
      <OverviewCard
        description="Need attention"
        filterText="Show skills"
        onClick={() =>
          selected('needAttention')
            ? selectCard('total')
            : selectCard('needAttention')
        }
        selected={selected('needAttention')}
        tooltipText="These are skills with unresolved comments, no description or have levels with no descriptions"
        value={needAttentionCount}
      />
    </div>
  )
}
