import { md5 } from 'hash-wasm'
import {
  applySnapshot,
  getSnapshot,
  IAnyType,
  Instance,
  SnapshotOrInstance,
  types,
} from 'mobx-state-tree'
import { AppConfig } from 'store/modules/config'
import { ApiClient, ApiClientOptions } from '../../api'
import {
  ClientOptions,
  JSONAPIRequestParams,
  RequestError,
  RequestResult,
} from '../../api/types'
import { exceptions } from '../../utils/exceptions'
import { getRootStore } from './get-root-store'
import { SchemaVersions } from '../../api/fetch-schema-versions'
import { Bootstrapper } from './bootstrapper'
import { BootstrapOptions, StoreOptions } from 'store/types'
import { applyState } from './apply-state'
import { camelize } from 'inflection'

export type StoreModule = ReturnType<typeof createStore>

export interface CreateStoreOptions<
  Model extends IAnyType,
  TypeConfig extends StoreOptions<Model>
> {
  api?: Omit<ApiClientOptions, 'baseUrl'>
  bootstrap?: BootstrapOptions<TypeConfig['Include']>
  hooks?: {
    afterDestroy?: (model: Instance<Model>) => Promise<void> | void
  }
  defaultFilter?: (item: Instance<Model>) => boolean
}

export const createStore = <
  Model extends IAnyType,
  TypeConfig extends StoreOptions<Model> = StoreOptions<Model>
>(
  storeName: string,
  model: Model,
  {
    defaultFilter = () => true,
    api = {},
    bootstrap,
    hooks = {},
  }: CreateStoreOptions<Model, TypeConfig> = {}
) => {
  const fullStoreName = `${storeName}Store`
  const modelName = camelize(model.name, true)
  return types
    .model(fullStoreName, {
      apiType: types.optional(types.string, model.name),
      initial: types.optional(types.boolean, true),
      loading: types.optional(types.boolean, false),
      error: types.maybeNull(types.string),
      data: types.optional(types.map(model), {}),
    })
    .views((store) => ({
      initialLoading() {
        return store.loading && store.initial
      },
      byId(
        id: string | undefined,
        options: { useDefaultFilter?: boolean } = { useDefaultFilter: true }
      ) {
        if (!id) return
        const found = store.data.get(id)
        if (!options.useDefaultFilter) return found
        if (found && options.useDefaultFilter && defaultFilter(found))
          return found
      },
      filtered(
        predicate: (item: Instance<Model>) => boolean,
        { useDefaultFilter = true } = {}
      ) {
        const arr = []
        for (const item of store.data.values()) {
          if (useDefaultFilter && !defaultFilter(item)) continue
          if (predicate(item as Instance<Model>)) arr.push(item)
        }
        return arr
      },
      get all() {
        return Array.from(store.data.values()).filter(defaultFilter)
      },
      get api() {
        return new ApiClient<
          Model,
          TypeConfig['Filters'],
          TypeConfig['Include'],
          TypeConfig['CreateAttributes'],
          TypeConfig['UpdateAttributes']
        >(model, { baseUrl: AppConfig.apiUrl, ...api })
      },
      async checksum() {
        const str = this.all
          .map((item) => item.id)
          .sort()
          .join('-')

        return md5(str)
      },
    }))
    .actions((store) => ({
      load(state: Record<string, SnapshotOrInstance<IAnyType>>) {
        for (const [id, newAttributes] of Object.entries(state)) {
          const existingAttributes = store.data.get(id)

          if (existingAttributes) {
            state[id] = {
              ...getSnapshot(existingAttributes),
              ...newAttributes,
            }
          }
        }

        store.data.merge(state)
      },
      saveResources(
        resources: Record<string, Record<string, unknown>> | null | undefined
      ) {
        if (!resources || Object.keys(resources).length === 0) return
        const root = getRootStore(store)
        applyState(root, resources)
      },
      unload(id: string) {
        store.data.delete(id)
      },
    }))
    .actions((store) => ({
      async makeRequest(
        fn: () => Promise<RequestResult>
      ): Promise<RequestResult> {
        store.loading = true

        let result: RequestResult | undefined
        try {
          result = await fn()
          if (result.success) {
            if (!result.cached) store.saveResources(result.data)
          } else {
            this.handleRequestError(result)
            exceptions.handleCustom(`${fullStoreName}Error`, {
              message: result.message,
              params: { ...result.extra, ...result.errors },
              tags: { requestId: result.requestId },
            })
          }
        } catch (e) {
          const error = e as Error
          this.handleRequestError({
            success: false,
            message: error.message,
          })
          exceptions.handle(error)
        } finally {
          this.completeRequest()
        }

        return result || { success: false }
      },
      handleRequestError(_error: RequestError) {
        // TODO: properly map errors to the store
        this.setError('Something went wrong. Please try again.')
      },
      setError(error: string | null) {
        store.error = error
      },
      completeRequest() {
        store.loading = false
        store.initial = false
      },
      async fetchAll(
        params?: JSONAPIRequestParams<
          TypeConfig['Include'],
          TypeConfig['Filters']
        >,
        options?: ClientOptions
      ) {
        return this.makeRequest(() => store.api.getMany(params, options))
      },
      async fetchOne(
        id: string,
        params?: JSONAPIRequestParams<
          TypeConfig['Include'],
          TypeConfig['Filters']
        >,
        options?: ClientOptions
      ) {
        return this.makeRequest(() => store.api.getOne(id, params, options))
      },
      async create(
        attributes: TypeConfig['CreateAttributes'],
        params?: JSONAPIRequestParams<
          TypeConfig['Include'],
          TypeConfig['Filters']
        >,
        options?: ClientOptions
      ): Promise<
        RequestResult & { createdId?: string; created?: Instance<Model> }
      > {
        const res = await this.makeRequest(() =>
          store.api.create(attributes, params, options)
        )
        let createdId: string | undefined
        let created: Instance<Model> | undefined
        if (res.success && res.data) {
          createdId = Object.keys(res.data[modelName])[0]
          created = store.byId(createdId)
        }
        return { ...res, createdId, created }
      },
      async update(
        id: string,
        attributes: TypeConfig['UpdateAttributes'],
        params?: JSONAPIRequestParams<
          TypeConfig['Include'],
          TypeConfig['Filters']
        >,
        options?: ClientOptions
      ) {
        return await this.makeRequest(() =>
          store.api.update(id, attributes, params, options)
        )
      },
      async destroy(
        id: string,
        params?: JSONAPIRequestParams<
          TypeConfig['Include'],
          TypeConfig['Filters']
        >,
        options?: ClientOptions
      ) {
        const model = store.byId(id)

        const result = await this.makeRequest(() =>
          store.api.destroy(id, params, options)
        )

        if (!result.success || !model) return result

        await hooks.afterDestroy?.(model)
        store.unload(id)
        return result
      },

      clear() {
        applySnapshot(store, {})
        Bootstrapper.resetStore(model)
      },
    }))
    .actions((store) => ({
      async bootstrap(versions: SchemaVersions = {}) {
        const boostrapper = new Bootstrapper(model, store, versions, bootstrap)
        return boostrapper.call((params) =>
          store.makeRequest(() => store.api.getMany(params))
        )
      },
    }))
}
