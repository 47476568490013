import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "input", "status", "statusText", "select"]

  save(event) {
    const form = this.element.closest("form") 
    this.element.requestSubmit();
    this.showStatus() 
  }

  showStatus() {
    const statusElement = document.querySelector('[data-position-mapping-target="status"]');
    const statusTextElement = document.querySelector('[data-position-mapping-target="statusText"]');
    const statusIcon = document.getElementById("svg")

    if (!statusElement || !statusTextElement) {
      console.error('Status targets not found');
      return;
    }
  
    this.lastUpdate = new Date();
    statusTextElement.textContent = "Saved just now";
    statusIcon.classList.remove('circle-icon')
    statusIcon.classList.add('circle-green-icon')
    
    if (this.interval) clearInterval(this.interval);
  
    this.interval = setInterval(() => {
      const elapsedSeconds = Math.round((new Date() - this.lastUpdate) / 1000);
  
      if (elapsedSeconds < 60) {
        statusTextElement.textContent = `Saved ${elapsedSeconds} sec ago`;
      } else {
        const elapsedMinutes = Math.floor(elapsedSeconds / 60);
        statusTextElement.textContent = `Saved ${elapsedMinutes} min ago`;
      }
    }, 10000); 
  }

  updateStatus(event) {
    this.updateAttribute(event, 'status');
  }

  updateNote(event) {
    this.updateAttribute(event, 'note');
  }

  updateAttribute(event, attribute) {
    const positionId = event.target.dataset.positionId;
    const newValue = event.target.value;
    const orgSlug = event.target.dataset.orgSlug; 
  
    fetch(`/orgs/${orgSlug}/position-aliases/${positionId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ [attribute]: newValue }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); 
      })
      .then(data => {
        this.showStatus(); 
      })
      .catch(error => {
        console.error('Error:', error);
      });
  } 

  deletePosition(event) {
    const positionId = event.target.dataset.positionId
    const orgSlug = event.target.dataset.orgSlug; 

    if (confirm('Are you sure you want to delete this position?')) {
      fetch(`/orgs/${orgSlug}/position-aliases/${positionId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        event.target.closest('tr').remove()
        this.showStatus()
      })
      .catch(error => {
        console.error('Error:', error)
      })
    }
  }

  triggerSort(event) {
    const column = event.currentTarget.dataset.column;

    const sortLink = this.element.querySelector(`[data-sort-link][data-column="${column}"]`);

    if (sortLink) {
      sortLink.click();
    }
  }
}