import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { store } from 'store/index'

export class SubscriptionBannerVm {
  constructor(private readonly trialEndsAt: number) {}

  get distanceInDaysToTrialEnd() {
    return (
      this.trialEndsAt &&
      formatDistanceToNowStrict(new Date(this.trialEndsAt * 1000), {
        roundingMethod: 'ceil',
        unit: 'day',
      })
    )
  }

  get billingLink() {
    return `/orgs/${store.currentUser?.org?.slug}/billing/new`
  }

  get bookACallLink(): string {
    const url = new URL('https://progression.co/talk-to-us/')
    if (store.currentUser?.fname)
      url.searchParams.append('firstname', store.currentUser?.fname)
    if (store.currentUser?.lname)
      url.searchParams.append('lastname', store.currentUser?.lname)
    if (store.currentUser?.email)
      url.searchParams.append('email', store.currentUser?.email)
    if (store.currentUser?.org?.name)
      url.searchParams.append('company', store.currentUser?.org?.name)

    return url.toString()
  }
}
