import { CellContext } from '@tanstack/react-table'
import { MinusCircle } from '@phosphor-icons/react'
import {
  ConfirmationDialog,
  OverflowMenu,
  useDrawerContext,
  useModalContext,
} from 'src/design-system'
import {
  Copy,
  Eye,
  GitBranch,
  PencilSimple,
  TextT,
  Trash,
} from '@phosphor-icons/react'
import * as React from 'react'
import { ActionsVm } from './actions-vm'
import { SKILL_MODAL_ID } from 'components/skill-modal/utils'
import { SkillVariantDialog } from 'components/skill-variant-dialog'
import { store } from 'store/index'
import { SKILL_COPY_DIALOG_ID } from 'components/skill-copy-dialog'
import { OrgSkillsTableRow } from '../data'
import {
  SKILL_INSIGHTS_DRAWER_ID,
  SkillInsightsDrawerProps,
} from 'pages/org-skills-page/side-panel'
import {
  DeletedSkillContext,
  RemoveSkillContext,
} from '../../deleted-skill-context'
import { useContext } from 'react'
import { SkillsService } from 'app/packs/src/services/skills-service'
import { useApiClient } from 'app/packs/src/utils/use-api-client'
import { useRailsContext } from 'app/packs/src/components/rails-context'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'

export const ActionsCell = (data: CellContext<OrgSkillsTableRow, unknown>) => {
  const row = data.row.original
  const { source } = ActionsVm
  const [openOverflowMenu, setOpenOverflowMenu] = React.useState(false)

  const [openSkillVariantDialog, setOpenSkillVariantDialog] =
    React.useState(false)

  const { openModal } = useModalContext()
  const { openDrawer } = useDrawerContext()
  const { setLastDeletedSkillId } = useContext(DeletedSkillContext)
  const { setLastRemovedSkillId } = useContext(RemoveSkillContext)
  const client = useApiClient()
  const { org } = useRailsContext()
  const service = React.useMemo(
    () => new SkillsService(client, org ?? undefined),
    [client, org]
  )
  const onRemoveFromTeams = React.useCallback(async () => {
    setIsRemoving(true)
    try {
      const result = await service.removeFromAllTeams(
        row.id,
        'org-skills-page-actions'
      )
      if (result.success) {
        setLastRemovedSkillId(row.id)
        successToast('Skill successfully removed from teams and positions.')
      } else {
        errorToast('Failed to remove skill from teams and positions.')
      }
    } catch (error) {
      alert('An error occurred while removing the skill.')
    } finally {
      setIsRemoving(false)
    }
  }, [row.id, service, setLastRemovedSkillId])

  const [confirmationDialog, setConfirmationDialog] = React.useState({
    title: '',
    body: '',
    onConfirm: () => {},
  })

  const [isRemoving, setIsRemoving] = React.useState(false)

  const vm = React.useMemo(() => new ActionsVm(row), [row])

  const onDestroy = React.useCallback(async () => {
    await vm?.destroy(setLastDeletedSkillId)
  }, [vm, setLastDeletedSkillId])

  const onMakeACopy = React.useCallback(async () => {
    setOpenOverflowMenu(false)
    const result = await vm?.makeACopy(row.id)
    if (result?.success)
      openModal(SKILL_MODAL_ID, {
        skillId: Object.keys(result.data?.skills || {})[0],
        source,
      })
  }, [openModal, row.id, vm, source])

  const onView = React.useCallback(async () => {
    openDrawer<SkillInsightsDrawerProps>(SKILL_INSIGHTS_DRAWER_ID, {
      skillId: row.id,
    })
  }, [openDrawer, row])

  const handleRemoveClick = () => {
    setOpenOverflowMenu(false)
    setConfirmationDialog({
      title: 'Are you sure you want to remove this skill',
      body: vm.confirmatDialogBody,
      onConfirm: onRemoveFromTeams,
    })
  }

  const handleDeleteClick = () => {
    setOpenOverflowMenu(false)
    setConfirmationDialog({
      title: vm.confirmationDialogTitle,
      body: vm.confirmationDialogBody,
      onConfirm: onDestroy,
    })
  }

  if (!vm) return null
  const canRemoveFromTeams =
    row.teamCount > 0 || row.positionCount > 0 || row.userCount > 0
  return (
    <div className="flex items-center justify-end">
      <ConfirmationDialog.Root
        body={confirmationDialog.body}
        onConfirm={confirmationDialog.onConfirm}
        title={confirmationDialog.title}
      >
        <div className="flex flex-col min-w-[50px]">
          <OverflowMenu.Root
            onOpenChange={setOpenOverflowMenu}
            open={openOverflowMenu}
            right
          >
            <OverflowMenu.Button onClick={onView}>
              <div className="flex items-center gap-2 text-gray-900">
                {vm.canEdit ? (
                  <>
                    <PencilSimple
                      aria-hidden
                      className="h-4 text-gray-600 w-4"
                      weight="bold"
                    />
                    View and edit
                  </>
                ) : (
                  <>
                    <Eye
                      aria-hidden
                      className="h-4 text-gray-600 w-4"
                      weight="bold"
                    />
                    View
                  </>
                )}
              </div>
            </OverflowMenu.Button>
            {vm.canCopy && store.featureGates.canAccess('skill_variants') ? (
              <OverflowMenu.Button
                className="bg-transparent flex focus:shadow-none gap-2 hover:!bg-gray-50 items-center justify-start min-w-full px-3 py-2.5 rounded-none text-gray-900"
                onClick={async () => {
                  const skill = await vm.fetchSkill()
                  if (!skill) return
                  openModal(SKILL_COPY_DIALOG_ID, {
                    onMakeACopy,
                    skill,
                    source,
                  })
                }}
              >
                <Copy
                  aria-hidden
                  className="h-4 w-4 text-gray-600"
                  weight="bold"
                />
                Make a copy
              </OverflowMenu.Button>
            ) : (
              vm.canCopy && (
                <OverflowMenu.Button
                  onClick={async () => {
                    setOpenOverflowMenu(false)
                    await vm.fetchSkill()
                    onMakeACopy()
                  }}
                >
                  <div className="flex gap-2 items-center text-gray-900">
                    <Copy
                      aria-hidden
                      className="h-4 text-gray-600 w-4"
                      weight="bold"
                    />
                    Make a copy
                  </div>
                </OverflowMenu.Button>
              )
            )}
            {vm.canCreateVariant && (
              <OverflowMenu.Button
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenOverflowMenu(false)
                  setOpenSkillVariantDialog(true)
                }}
              >
                <div className="flex gap-2 items-center text-gray-900">
                  <GitBranch
                    aria-hidden
                    className="h-4 text-gray-600 w-4"
                    weight="bold"
                  />
                  Create variant...
                </div>
              </OverflowMenu.Button>
            )}
            {canRemoveFromTeams && vm.canRemove && (
              <ConfirmationDialog.Trigger asChild>
                <OverflowMenu.Button
                  onClick={(e) => {
                    e.stopPropagation()
                    handleRemoveClick()
                    setOpenOverflowMenu(false)
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <MinusCircle
                      aria-hidden
                      className="h-4 w-4"
                      weight="bold"
                    />
                    Remove from teams and positions
                  </div>
                </OverflowMenu.Button>
              </ConfirmationDialog.Trigger>
            )}

            {vm.canDelete && (
              <ConfirmationDialog.Trigger asChild>
                <OverflowMenu.Button
                  variant="destructive"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDeleteClick()
                    setOpenOverflowMenu(false)
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <Trash aria-hidden className="h-4 w-4" weight="bold" />
                    Delete
                  </div>
                </OverflowMenu.Button>
              </ConfirmationDialog.Trigger>
            )}
          </OverflowMenu.Root>
        </div>
      </ConfirmationDialog.Root>
      <SkillVariantDialog
        defaultOpen={openSkillVariantDialog}
        onOpenChange={setOpenSkillVariantDialog}
        skillId={row.id}
        source={source}
      />
    </div>
  )
}
