import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { EditableByInfoLine } from './editable-by-info-line'
import { LicenseInfoLine } from './license-info-line'
import { Link } from 'src/design-system'
import { OrgInfoLine } from './org-info-line'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { UsedByInfoLine } from './used-by-info-line'
import { TabbedInfoSection } from 'components/tabbed-info-section'
import { PeopleSection } from './people-section'
import { LoadingContent } from 'components/loading-content'

type InfoPaneProps = {
  editable: boolean
  skillVariant: SkillVariant
  canAddToOrg?: boolean
}

export const InfoPane = observer((props: InfoPaneProps) => {
  const { editable, skillVariant, canAddToOrg } = props

  const skill = skillVariant.skill
  const skillId = skill?.id
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchPreview = async (abortSignal: AbortSignal) => {
      if (!skillId) return
      try {
        await store.skills.fetchOne(
          skillId,
          {
            include: [
              'frameworks_skills',
              'frameworks_skills.framework',
              'frameworks_skills.framework.team',
              'requirements',
              'skill_variants',
              'skill_variants.skill_levels',
              'skill_variants.skill_levels.outcomes',
            ],
          },
          { abortSignal }
        )
      } catch (error) {
        if (abortSignal.aborted) {
          console.log('Fetch aborted')
        } else {
          console.error('Failed to fetch skill:', error)
        }
      } finally {
        setLoading(false)
      }
    }

    const controller = new AbortController()
    fetchPreview(controller.signal)
    return () => controller.abort()
  }, [skillId])

  if (!skill) return null

  const { defaultVariant } = skill

  const editableInfoLine = editable && (!skillVariant || skillVariant.default)

  return (
    <div className="flex flex-col gap-8">
      {canAddToOrg && (
        <>
          {skill.org && <OrgInfoLine org={skill.org} />}
          {skill.contentLicenseGrant && (
            <LicenseInfoLine license={skill.contentLicenseGrant} />
          )}
        </>
      )}
      {!canAddToOrg && (
        <>
          {skillVariant.teams.length > 0 && (
            <UsedByInfoLine teams={skillVariant.teams} />
          )}
          <EditableByInfoLine skill={skill} editable={editableInfoLine} />
        </>
      )}
      {editable && <TabbedInfoSection resource={skillVariant} />}
      <LoadingContent loading={loading}>
        {defaultVariant && <PeopleSection variant={skillVariant} />}
      </LoadingContent>
    </div>
  )
})
