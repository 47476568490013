import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SkillModalButton } from 'components/skill-modal/skill-modal-button'
import { OrgSkillsTableRow } from '../data'
import { ChatTeardropText } from '@phosphor-icons/react'
import { BulkDeletedSkillContext } from '../../deleted-skill-context'
import { useContext, useEffect } from 'react'
import { ActionsVm } from './actions-vm'

export const NameCell = observer(
  (data: CellContext<OrgSkillsTableRow, unknown>) => {
    const row = data.row.original
    const variantnames = row.variantNames
    const [isHovered, setIsHovered] = React.useState(false)
    const vm = React.useMemo(() => {
      return new ActionsVm(row)
    }, [row])
    const context = useContext(BulkDeletedSkillContext)
    const checkedSkillIds = context?.checkedSkillIds ?? []
    const addCheckedSkillId = context?.addCheckedSkillId ?? (() => {})
    const removeCheckedSkillId = context?.removeCheckedSkillId ?? (() => {})

    const isChecked = checkedSkillIds.includes(row.id)

    const handleMouseEnter = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    const handleCheckboxChange = () => {
      if (isChecked) {
        removeCheckedSkillId(row.id)
      } else {
        addCheckedSkillId(row.id)
      }
    }

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          checkedSkillIds.forEach((id) => removeCheckedSkillId(id))
        }
      }

      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }, [checkedSkillIds, removeCheckedSkillId])

    return (
      <div className="flex gap-x-2 items-center">
        <div
          className={`relative bg-image-cover flex-shrink-0 h-8 rounded w-8 ${
            vm.canDelete && (isHovered || isChecked)
              ? ''
              : 'border border-white'
          }`}
          style={{
            backgroundImage:
              vm.canDelete && (isHovered || isChecked)
                ? 'none'
                : `url("${row.imageUrl}")`,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {vm.canDelete && (isHovered || isChecked) && (
            <input
              type="checkbox"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-4 w-4"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          )}
        </div>
        <div className="flex-clon">
          <SkillModalButton
            className="font-bold text-gray-900 text-sm flex gap-3 hover:underline"
            skillId={row.id}
            skillVariantId={row.variantId}
            source="org-skills-page-skill-name"
          >
            {row.name}
            {row.commentCount > 0 && (
              <div className="flex gap-1 items-center">
                <ChatTeardropText
                  className="h-4 text-gray-400 w-4"
                  weight="bold"
                />
                <span className="font-normal text-xs">{row.commentCount}</span>
              </div>
            )}
          </SkillModalButton>
          <div className="text-xs text-gray-500">
            {variantnames.length > 0
              ? variantnames
                  .filter((name) => name !== 'Main')
                  .map((name, index, array) => (
                    <React.Fragment key={index}>
                      <SkillModalButton
                        className="text-gray-500 inline-flex hover:underline"
                        skillId={row.id}
                        skillVariantId={row.variantId}
                        source="org-skills-page-variant-name"
                      >
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                      </SkillModalButton>
                      {index < array.length - 1 && <span>, </span>}
                    </React.Fragment>
                  ))
              : null}
          </div>
        </div>
      </div>
    )
  }
)
