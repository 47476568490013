import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
 
  debounceTimeout = null;

  submit(event) {
    
    clearTimeout(this.debounceTimeout);
   
    this.debounceTimeout = setTimeout(() => {
    
      this.element.requestSubmit();
    }, 300); 
  }
}
