import { Button } from 'src/design-system'
import { flexRender } from '@tanstack/react-table'
import { MinusCircle, Pencil, UsersThree, Trash } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OverviewCards, OverviewCardType } from '../overview-cards'
import { SearchFilter } from 'components/search-filter'
import { Select } from 'components/atoms/select'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { useTable } from './context/table'
import * as Table from 'components/table'
import { Filters } from './filters'
import { useModalContext } from 'src/design-system'
import { SKILL_MODAL_ID, SkillModalProps } from 'components/skill-modal/utils'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { SkillsService } from 'app/packs/src/services/skills-service'
import { useApiClient } from 'app/packs/src/utils/use-api-client'
import { useRailsContext } from 'app/packs/src/components/rails-context'
import {
  BulkDeletedSkillContext,
  DeletedSkillContext,
} from 'app/packs/src/pages/org-skills-page/deleted-skill-context'

import { ConfirmationDialog } from 'src/design-system'

export const OrgSkillsTable = observer(() => {
  const { openModal } = useModalContext()
  const { columnFilters, data, globalFilter, setGlobalFilter, table, loading } =
    useTable()

  const source = 'org-skills-table'

  const isAdmin = !!store.currentUser?.isAdmin
  const isAdminOrEditor = !!store.currentUser?.isAdminOrEditor

  const [selectedCard, setSelectedCard] =
    React.useState<OverviewCardType>('total')

  const filterValue = (columnId: string) => {
    return columnFilters.find((column) => column.id === columnId)?.value
  }

  const filters = React.useMemo(() => new Filters(data), [data])
  const client = useApiClient()
  const { org } = useRailsContext()
  const service = React.useMemo(
    () => new SkillsService(client, org ?? undefined),
    [client, org]
  )
  const onClearFilters = () => {
    table.resetColumnFilters()
    table.resetGlobalFilter()
    table.resetSorting()
    setSelectedCard('total')
  }

  const onFilterChange = (column: string, value: string | string[]) => {
    table.getColumn(column)?.setFilterValue(value)
    trackEvent('$track_org_skills_filter_changed', { [column]: value })
  }

  const onOverviewCardClick = (overviewCardType: OverviewCardType) => {
    trackEvent('$track_org_skills_card_clicked', { card: overviewCardType })

    switch (overviewCardType) {
      case 'needAttention':
        table.getColumn('needAttention')?.setFilterValue(true)
        table.getColumn('positions')?.setFilterValue(undefined)
        table.getColumn('teamCount')?.setFilterValue(undefined)
        break
      case 'total':
        table.getColumn('needAttention')?.setFilterValue(undefined)
        table.getColumn('positions')?.setFilterValue(undefined)
        table.getColumn('teamCount')?.setFilterValue(undefined)
        break
      case 'unused':
        table.getColumn('needAttention')?.setFilterValue(undefined)
        table.getColumn('positions')?.setFilterValue(0)
        table.getColumn('teamCount')?.setFilterValue(undefined)
        break
      case 'unusedInTeam':
        table.getColumn('needAttention')?.setFilterValue(undefined)
        table.getColumn('positions')?.setFilterValue(0)
        table.getColumn('teamCount')?.setFilterValue(0)
    }
  }

  const showClearFiltersButton = React.useMemo(() => {
    return columnFilters.length > 0 || (globalFilter && globalFilter !== '')
  }, [columnFilters, globalFilter])

  const openSkillModal = (skillId: string, skillVariantId?: string) => {
    openModal<SkillModalProps>(SKILL_MODAL_ID, {
      skillId,
      skillVariantId,
      source,
    })
  }

  const { checkedSkillIds, addCheckedSkillId, removeCheckedSkillId } =
    React.useContext(BulkDeletedSkillContext)
  const { setLastDeletedSkillId } = React.useContext(DeletedSkillContext)
  const isDeleteButtonVisible = checkedSkillIds.length > 0
  const selectedCount = checkedSkillIds.length

  const [isDeleting, setIsDeleting] = React.useState(false)

  const handleDelete = React.useCallback(async () => {
    setIsDeleting(true)
    try {
      const result = await service.bulkDestroy(checkedSkillIds)

      if (result.success) {
        successToast('Skill deleted')
        checkedSkillIds.forEach((id) => {
          setLastDeletedSkillId(id)
        })
        checkedSkillIds.forEach((id) => removeCheckedSkillId(id))
      } else {
        errorToast('Failed to delete skill(s).')
      }
    } catch (error) {
      errorToast('An error occurred while deleting skills.')
    } finally {
      setIsDeleting(false)
    }
  }, [checkedSkillIds, service, setLastDeletedSkillId, removeCheckedSkillId])

  const handleBulkSelectToggle = () => {
    const allCurrentPageSkillIds = table
      .getRowModel()
      .rows.map((row) => row.original.id)

    const allSelected = allCurrentPageSkillIds.every((id) =>
      checkedSkillIds.includes(id)
    )

    if (allSelected) {
      allCurrentPageSkillIds.forEach(removeCheckedSkillId)
    } else {
      allCurrentPageSkillIds.forEach((id) => {
        if (!checkedSkillIds.includes(id)) {
          addCheckedSkillId(id)
        }
      })
    }
  }

  return (
    <div>
      {isAdminOrEditor && (
        <OverviewCards
          needAttentionCount={filters.needAttentionCount(
            table.getState().columnFilters,
            globalFilter
          )}
          onClick={(overviewCardType) => onOverviewCardClick(overviewCardType)}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          totalCount={filters.totalCount(
            table.getState().columnFilters,
            globalFilter
          )}
          unusedCount={filters.unusedCount(
            table.getState().columnFilters,
            globalFilter
          )}
          unusedInTeamCount={filters.unusedInTeamCount(
            table.getState().columnFilters,
            globalFilter
          )}
        />
      )}
      <div className="pt-6">
        <div className="flex flex-wrap gap-2 mb-5 px-4">
          <SearchFilter
            name="org-skills-search"
            onChange={(value) => {
              setGlobalFilter(String(value))
            }}
            value={globalFilter ?? ''}
          />
          <Select
            isMulti
            isSearchable
            onChange={(values: string[]) => onFilterChange('teams', values)}
            options={filters.teamOptions}
            triggerContent={<TeamTriggerContent />}
            value={filterValue('teams') as string[] | undefined}
          />
          {isAdminOrEditor && (
            <Select
              onChange={(value: string) =>
                onFilterChange('editPermission', value)
              }
              options={filters.editPermissionOptions}
              triggerContent={<EditPermissionTriggerContent />}
              value={filterValue('editPermission') as string}
            />
          )}
          {showClearFiltersButton && (
            <Button
              className="h-[34px]"
              onClick={onClearFilters}
              variant="outline"
            >
              <MinusCircle
                aria-hidden
                className="h-4 text-theme-40 w-4"
                weight="bold"
              />
              Clear filters
            </Button>
          )}
        </div>
        <div className="overflow-x-auto mt-10">
          <ConfirmationDialog.Root
            title={`Are you sure you want to delete ${selectedCount} skill${
              selectedCount > 1 ? 's' : ''
            }? `}
            body={
              'This will permanently remove there skills and all of their variants from Progression.'
            }
            onConfirm={handleDelete}
            confirmLabel={`Delete ${selectedCount} skill${
              selectedCount > 1 ? 's' : ''
            }`}
          >
            <Table.Root>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <Table.Header
                        key={header.id}
                        header={header}
                        loading={loading}
                        source={source}
                      >
                        {index === 0 && isAdminOrEditor ? (
                          <div className="flex gap-x-2 pl-2">
                            <input
                              type="checkbox"
                              className="h-4 w-4"
                              checked={
                                table.getRowModel().rows.length > 0 &&
                                table
                                  .getRowModel()
                                  .rows.every((row) =>
                                    checkedSkillIds.includes(row.original.id)
                                  )
                              }
                              onChange={handleBulkSelectToggle}
                            />
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </Table.Header>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>

              <Table.Body>
                {table.getRowModel().rows.length === 0 && (
                  <Table.NoResultsRow colspan={table.getAllColumns().length} />
                )}

                {table.getRowModel().rows.map((row, index) => {
                  const cells = row.getVisibleCells()
                  const skillId = row.original.id

                  return (
                    <Table.Row
                      className={
                        checkedSkillIds.includes(row.original.id)
                          ? 'bg-gray-50/50'
                          : ''
                      }
                      showBottomBorder={
                        index < table.getRowModel().rows.length - 1
                      }
                      key={row.id}
                      onClick={
                        isAdmin
                          ? (e) => {
                              if (e.target instanceof HTMLButtonElement) return
                              if (
                                e.target instanceof HTMLInputElement &&
                                e.target.type === 'checkbox'
                              ) {
                                return
                              }
                              if (
                                (e.target as HTMLElement).closest(
                                  '[role="dialog"]'
                                )
                              )
                                return
                              openSkillModal(skillId)
                            }
                          : undefined
                      }
                    >
                      {cells.map((cell) => (
                        <Table.Cell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
              {isDeleteButtonVisible && (
                <div className="fixed bottom-6 left-[60%] px-6 py-3 translate-x-[-50%]">
                  <div className="bg-white rounded-lg shadow-lg border border-gray-200">
                    <div className="flex items-center gap-4 px-6 py-3">
                      <span className="text-sm text-gray-600">
                        {selectedCount} selected
                      </span>
                      <div className="flex gap-2">
                        <ConfirmationDialog.Trigger asChild>
                          <Button
                            size="sm"
                            className="text-sm text-red-600 border bg-white ring ring-red-200"
                          >
                            <Trash className="w-4 h-4 mr-1" />
                            Delete
                          </Button>
                        </ConfirmationDialog.Trigger>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Table.Root>
          </ConfirmationDialog.Root>
          <div className="px-3 flex justify-center">
            <Table.Pagination table={table} />
          </div>
        </div>
      </div>
    </div>
  )
})

const EditPermissionTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <Pencil aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All edit permissions
    </div>
  )
}

const TeamTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <UsersThree aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All teams
    </div>
  )
}
