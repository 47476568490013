import pluralize from 'pluralize'
import { store } from 'store/index'
import { Skill } from 'store/modules/skills'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { OrgSkillsTableRow } from '../data'
import { SkillPolicy } from 'app/packs/src/policies/skill-policy'

export class ActionsVm {
  constructor(private row: OrgSkillsTableRow) {}

  public static source = 'org-skills-page-actions'

  get skill() {
    return store.skills.byId(this.row.id)
  }

  async fetchSkill() {
    if (this.skill) return this.skill

    store.skills.fetchOne(this.row.id)
    return this.skill
  }

  async makeACopy(id: string) {
    const result = await store.skills.create(
      { clonedFrom: id },
      {
        include: [
          'skill_variants',
          'skill_variants.skill_levels',
          'skill_variants.skill_levels.outcomes',
        ],
        source: ActionsVm.source,
      }
    )

    if (result.success) {
      successToast('Skill copied')
    } else {
      errorToast()
    }

    return result
  }

  async destroy(setLastDeletedSkillId: (id: string) => void) {
    const result = await store.skills.destroy(this.row.id, {
      source: ActionsVm.source,
    })
    if (result.success) {
      setLastDeletedSkillId(this.row.id)
      successToast('Skill deleted')
    }

    if (!result.success) errorToast()
  }

  get canCopy() {
    return !!store.currentUser && this.policy.copy
  }

  get canCreateVariant() {
    return this.skillVariantsEnabled && this.canEdit
  }

  get canDelete() {
    return !!store.currentUser && this.policy.destroy
  }

  get canRemove() {
    return !!store.currentUser && this.policy.removeFromTeams
  }

  get canEdit() {
    return !!store.currentUser && this.policy.edit
  }

  get canEditVariant() {
    return this.skillVariantsEnabled && !!store.currentUser && this.policy.edit
  }

  get confirmationDialogBody() {
    return 'This will permanently remove this skill and its variants from Progression.'
  }

  get confirmatDialogBody() {
    return 'This will remove Using Data (and any variants) from all team and positions'
  }

  get confirmationDialogTitle() {
    const variantCount = this.skill?.nonDefaultSkillVariants.length ?? 0

    return variantCount > 0
      ? `Delete this skill and ${pluralize('variant', variantCount, true)}?`
      : 'Delete this skill?'
  }

  private get skillVariantsEnabled() {
    return store.featureGates.canAccess('skill_variants')
  }

  private get policy() {
    return new SkillPolicy(store.nonNullCurrentUser, {
      id: this.row.id,
      editableAdminOnly: this.row.editableBy === 'admin_only',
      org: store.nonNullCurrentUser.org,
    } as Skill)
  }
}
