import { Skill } from 'store/modules/skills'
import { BasePolicy } from '../base-policy'

export class SkillPolicy extends BasePolicy<Skill> {
  public get copy() {
    if (!this.orgId) return false

    return (
      this.user.isAdminOfOrg(this.orgId) || this.user.isEditorOfOrg(this.orgId)
    )
  }

  public get destroy() {
    return !!this.orgId && this.user.isAdminOfOrg(this.orgId)
  }

  public get edit() {
    if (!this.orgId) return false

    return (
      this.user.isAdminOfOrg(this.orgId) ||
      (this.user.isEditorOfOrg(this.orgId) && !this.object.editableAdminOnly)
    )
  }

  private get orgId() {
    return this.object.org?.id
  }

  public get removeFromTeams() {
    return !!this.orgId && this.user.isAdminOfOrg(this.orgId)
  }
}
